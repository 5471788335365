import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  constructor( public loadController: LoadingController, private _snackBar: MatSnackBar) { }

  toastInformativo(message: string, color: string) {
    this._snackBar.open(message, 'Ok', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
  async presentLoading(message: string) {
    const loading = await this.loadController.create({
      message,
    });
    await loading.present();
  }
  paraLoading() {
    this.loadController.dismiss();
  } 
  graficar(porcentaje: any) {
    return 'transform: rotate(' + this.calcularProgreso(porcentaje) + ');';
  }
  graficarBefore(porcentaje: any) {
    return 'transform: rotate(' + this.calcularProgresoNegativo(porcentaje) + ');';
  }
  graficarAfter(porcentaje: any) {
    return 'transform: scale(1.1) rotate(' + this.calcularProgresoNegativo(porcentaje) + ');';
  }
  calcularProgreso(progeso: any) {
    let grados: any = progeso / 100 * 180;
    return grados + 'deg';
  }
  calcularProgresoNegativo(progeso: any) {
    let grados: any = (progeso / 100 * 180) * -1;
    return grados + 'deg';
  }
}
