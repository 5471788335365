import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UiService } from "./ui.service";
import { UserInterface } from "@interfaces/user/user.interface";

@Injectable({
  providedIn: "root",
})
export class UserService {
  urlFinal: string;
  token: string = null;
  user: UserInterface = {};
  storage: any = localStorage;
  apiUrl = environment.apiUrl;
  constructor(
    private http: HttpClient,
    private router: Router,
    private uiService: UiService
  ) {
    this.loadToken();
  }
  async login(correo: string, contrasena: string) {
    await this.uiService.presentLoading("Cargando...");
    const data = { correo: correo, contrasena: contrasena };
    this.urlFinal = this.apiUrl + "usuario-login-profesor";
    return new Promise((resolve) => {
      this.http.post<any>(this.urlFinal, data).subscribe((resp) => {
        this.uiService.paraLoading();
        if (resp.respuesta) {
          this.saveToken(resp.usuario.acc_token);
          this.user = resp.usuario;
          this.token = resp.usuario.acc_token;
          resolve(true);
        } else {
          this.token = null;
          this.storage.clear();
          resolve(false);
        }
      });
    });
  }

  saveToken(token: string) {
    this.token = token;
    this.storage.setItem("token", this.token);
  }
  
  loadToken() {
    return this.storage.getItem("token") || null;
  }

  async validateToken(): Promise<boolean> {
    const token : string | null = this.loadToken();
    if (token == null) {
      this.router.navigateByUrl("/login");
      return Promise.resolve(false);
    }
    this.urlFinal = this.apiUrl + "usuario-token";
    const headers = new HttpHeaders({
      "x-token": token,
    });
    return new Promise<boolean>((resolve) => {
      this.http.get<any>(this.urlFinal, { headers }).subscribe((resp) => {
        if (resp.respuesta) {
          this.user = resp.usuario;
          if (this.user?.tipo == "2") {
            resolve(true);
          } else {
            resolve(false);
          }
        } else {
          this.router.navigateByUrl("/login");
          resolve(false);
        }
      });
    });
  }

  getUserToken() {
    if (!this.user.id) {
      this.validateToken();
    }
    return { ...this.user };
  }
  
  exit() {
    this.token = null;
    this.user = {};
    this.storage.clear();
    this.validateToken();
  }
}
