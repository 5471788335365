import { Injectable } from '@angular/core';
import { CanLoad, CanActivate } from '@angular/router';
import { UserService } from '@services/user.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GuardianGuard implements CanLoad, CanActivate {

  constructor( private userS: UserService) {}
  canLoad( ): Observable<boolean> | Promise <boolean> | boolean {
    return this.userS.validateToken();
  }

  canActivate( ): Observable<boolean> | Promise <boolean> | boolean {
    return this.userS.validateToken();
  }
}