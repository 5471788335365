import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuardianGuard } from './core/guards/guardian.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./modules/public/login/login.module').then( m => m.LoginModule)    
  },
  {
    path: 'panel',
    loadChildren: () => import('./modules/private/private.module').then( m => m. PrivateModule) ,
    canLoad: [GuardianGuard]   
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
